import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faExternalLinkAlt, faTimesCircle, faCheckCircle, faCalendarAlt, faUser, faCodeBranch, faShoppingCart, faFolder, faMapMarkedAlt, faPager, faFileCode, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faBootstrap, faGithub, faJs, faReact, faSass, faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, Container, ListGroup, Tooltip, OverlayTrigger, InputGroup, Form, Nav, NavDropdown, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Navbar from 'react-bootstrap/Navbar';

import { Routes } from "../routes";
// import ThemesbergLogoIcon from "../assets/img/themesberg.svg";
// import ThemesbergLogo from "../assets/img/themesberg-logo.svg";


import webinarlogo from "../assets/img/brand/webinar.png";

import VerifyImg from "../assets/img/icons/verify.svg";
// import PersonsImg from "../assets/img/icons/persons.svg";
import DownArrowImg from "../assets/img/icons/down-arrow.svg";
import DotImg from "../assets/img/icons/dots.svg";
// import HosteventImg from "../assets/img/icons/img1.svg";
import HosteventImg from "../assets/img/brand/abt-2.svg";
import SlickDemo from "../components/Slider";
import InfiniteCarousel from 'react-leaf-carousel';
import Slider from "react-slick";


import zoom from "../assets/img/icons/zoom.png";
import kosmos from "../assets/img/icons/1kosmos.png";
import incube from "../assets/img/icons/incube.png";
import amity from "../assets/img/brand/amity.png";
import gems from "../assets/img/brand/gems.png";
import jebel from "../assets/img/brand/jebel.png";
import sohau from "../assets/img/brand/sohau.png";
import swiss from "../assets/img/brand/swiss.png";
import koparu from "../assets/img/brand/koparu.png";

import data_science from "../assets/img/homewebinarslide/data_science.svg";
import dealing_with_design from "../assets/img/homewebinarslide/dealing_with_design.svg";
import e_live from "../assets/img/homewebinarslide/e_live.svg";
import Entrepreneur_101 from "../assets/img/homewebinarslide/Entrepreneur_101.svg";
import how_to_become_a_top_paid from "../assets/img/homewebinarslide/how_to_become_a_top_paid.svg";
import social_media_tactics from "../assets/img/homewebinarslide/social_media_tactics.svg";
import webinarslide from "../assets/img/homewebinarslide/webinar.svg";
import why_does_it from "../assets/img/homewebinarslide/why_does_it.svg";

// import landing
import features from "../data/features";

export default () => {
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link as={Link} to={link} className="page-preview page-preview-lg scale-up-hover-2">
          <Image src={image} className="shadow-lg rounded scale" alt="Dashboard page preview" />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name} <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Webinarcards = (props) => {
    const settings = {
      className: "center",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 3,
      swipeToSlide: true,
      rows: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="scheduled-webinars">

        <Slider {...settings}>

          <div className="web-cards">
            <div className="web-cards-inner">
              <Image src={Entrepreneur_101} />
              <p>TEDxWHU 2021 - Embrace Change<br />Jan 30,5:30 PM GMT +5:30<br /><span className="clr_primary">AED 150.00</span></p>
            </div>
          </div>
          <div className="web-cards">
            <div className="web-cards-inner">
              <Image src={social_media_tactics} />
              <p>TEDxWHU 2021 - Embrace Change<br />Jan 30,5:30 PM GMT +5:30<br /><span className="clr_primary">AED 150.00</span></p>
            </div>
          </div>



          <div className="web-cards">
            <div className="web-cards-inner">
              <Image src={why_does_it} />
              <p>TEDxWHU 2021 - Embrace Change<br />Jan 30,5:30 PM GMT +5:30<br /><span className="clr_primary">AED 150.00</span></p>
            </div>
          </div>
          <div className="web-cards">
            <div className="web-cards-inner">
              <Image src={webinarslide} />
              <p>TEDxWHU 2021 - Embrace Change<br />Jan 30,5:30 PM GMT +5:30<br /><span className="clr_primary">AED 150.00</span></p>
            </div>
          </div>



          <div className="web-cards">
            <div className="web-cards-inner">
              <Image src={data_science} />
              <p>TEDxWHU 2021 - Embrace Change<br />Jan 30,5:30 PM GMT +5:30<br /><span className="clr_primary">AED 150.00</span></p>
            </div>
          </div>
          <div className="web-cards">
            <div className="web-cards-inner">
              <Image src={e_live} />
              <p>TEDxWHU 2021 - Embrace Change<br />Jan 30,5:30 PM GMT +5:30<br /><span className="clr_primary">AED 150.00</span></p>
            </div>
          </div>



          <div className="web-cards">
            <div className="web-cards-inner">
              <Image src={how_to_become_a_top_paid} />
              <p>TEDxWHU 2021 - Embrace Change<br />Jan 30,5:30 PM GMT +5:30<br /><span className="clr_primary">AED 150.00</span></p>
            </div>
          </div>
          <div className="web-cards">
            <div className="web-cards-inner">
              <Image src={dealing_with_design} />
              <p>TEDxWHU 2021 - Embrace Change<br />Jan 30,5:30 PM GMT +5:30<br /><span className="clr_primary">AED 150.00</span></p>
            </div>
          </div>

        </Slider>
      </div>
    );
  }

  const Feature = (props) => {
    const { title, description, icon, index } = props;

    if (index === 4) {
      return (
        <>
          <Col xs={12} sm={6} lg={2}>
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <div className="px-3 px-lg-4 py-4 feat_text">
              <span className="feat mb-4 d-block">
                <Image src={icon} />
              </span>
              <h5 className="fw-bold text-primary">{title}</h5>
              <p className="mb-0">{description}</p>
            </div>
          </Col>
        </>
      )
    } else if (index === 5) {
      return (
        <>

          <Col xs={12} sm={6} lg={4}>
            <div className="px-3 px-lg-4 py-4 feat_text">
              <span className="feat mb-4 d-block">
                <Image src={icon} />
              </span>
              <h5 className="fw-bold text-primary">{title}</h5>
              <p className="mb-0">{description}</p>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={2}>
          </Col>
        </>
      )
    } else {
      return (
        <Col xs={12} sm={6} lg={3}>
          <div className="px-3 px-lg-4 py-4 feat_text">
            <span className="feat mb-4 d-block">
              <Image src={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p className="mb-0">{description}</p>
          </div>
        </Col>
      );
    }

  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li data-toggle="tooltip" data-placement="left" title="Main folder that you will be working with">
          <FontAwesomeIcon icon={icon ? icon : faFolder} className={`${color} me-2`} /> {name}
        </li>
      </OverlayTrigger>
    );
  };

  return (
    <>


      <section className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-12 landing-bg vh100" id="home">
        <Container>
          <Row className="d-flex align-items-center banner-text-center">
            <Col xs={12} lg={5} className="heading1">
              <h1 className="fw-bolder clr_sec">Create <span className="clr_primary">virtual events</span> people love</h1>
              <p>webinar.media is an online events platform where you can create engaging virtual events that connect people around the globe.</p>
              <Button href="" className="mt-3 bgclr_primary" target="_blank">Sign up For Free</Button>

              <div className="l-da-icn mt-5">
                <Image src={DownArrowImg} />
              </div>

              <div className="dotimg">
                <Image src={DotImg} />
              </div>

            </Col>
            <Col xs={12} lg={7} className="">
              <Row>
                <Col lg={1} >
                </Col>
                <Col lg={11} >
                  {/* <Image src={PersonsImg} alt="Calendar Preview" /> */}
                </Col>
                <Col lg={1} >
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row className="d-flex align-items-center">


            <Col xs={12} lg={6} className="">
              <Image src={HosteventImg} />
            </Col>

            <Col  xs={12} lg={{ span: 4, offset: 1 }} className="heading1" >
              <h1 className="fw-bolder clr_sec">Host your most <span className="clr_primary">interactive online</span> event yet</h1>
              <p>webinar.media is a virtual venue with multiple interactive areas that are optimized for connecting and engaging. Attendees can move in and out of rooms just like an in-person event and enjoy the content and connections you've created for them. The results? High show-up rates. low drop-off rates. and happy attendees.</p>

              <p>Host events of any type size | Sell event tickets Engage your attendees | Analyze data from your event</p>
              <hr></hr>
              <Row className=" d-flex align-items-center img1">
                <Col lg={4}>
                  <Image src={zoom} />
                </Col>
                <Col lg={4}>
                  <Image src={kosmos} />
                </Col>
                <Col lg={4}>
                  <Image src={incube} />
                </Col>
              </Row>
            </Col>

          </Row>
        </Container>
      </section>


      <section className="section bgclr_light">
        <Container>
          <Row className="justify-content-center ">
            <Col xs={8} className="text-center col-12">
              <h2 className="px-lg-4 fnt-wt-700 mb-4 clr_sec">webinar.media capabilities that help you <span className="clr_primary">reach more</span> people and <span className="clr_primary">grow your</span> business</h2>

            </Col>
          </Row>
          <Row>
            {features.map((feature, index) => <Feature index={index} key={`features-${feature.id}`} {...feature} />)}
          </Row>
        </Container>
      </section>

      <section className="Service1">
        <Container>
          <Row className="d-flex align-items-center">
            <Col xs={12} lg={8} className="heading1">
              <h1 className="fw-bolder clr_sec">Services they can use in <span className="clr_primary">webinar.media</span></h1>
              <p>Organizers can achieve the same goals of their offline events by customizing their Hopin events to fit the requirements, whether it's a 50-person recruiting event, a 500-person all-hands meeting, or a 50,000-person annual conference.
              <br />Hopin is built knowing every even is unique.</p>
              <Row className="d-flex align-items-center services-wrapper">
                <Col className="col-6" lg={4}>
                  <ul>
                    <li>Livestream</li>
                    <li>Registration</li>
                    <li>Recording</li>
                    <li>Chat</li>
                    <li>Q&A</li>
                    <li>Audio sharing</li>
                    <li>Video sharing</li>
                    
                  </ul>
                </Col>
                <Col className="col-6" lg={4}>
                  <ul>
                  <li>Polling</li>
                    <li>Capacity</li>
                    <li>Whiteboard</li>
                  
                    <li>Waiting room</li>
                    <li>File transfer</li>
                  
                    <li>Nonverbal feedback</li>
                    <li>Participants list</li>
                    
                   
                    
                  </ul>
                </Col>
                <Col className="col-12" lg={4} >
                  <ul>
                  <li>Participant Roles</li>
                  <li>Screen sharing</li>
                  <li>Meeting reactions</li>
                  
                    <li>Email reminders</li>
                    <li>Require password to join</li>
                   
                    <li>International dial-in numbers</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            {/* <Col xs={12} lg={{ span: 6, offset: 1 }} className="">
              <SlickDemo />
            </Col> */}
          </Row>
        </Container>
      </section>
{/* 
      <section>

      </section> */}
      <section className="section section-md webinar-slider-secbg pt-lg-3">
        <Container>
          <Row>
            <Col style={{textAlign:"center"}} lg={{ span: 10, offset: 1 }}>
              <h1 className="fw-bolder clr_sec">Join 100 of organizers hosting creative events on <span className="clr_primary">webinar.media</span></h1>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center">
            <Col lg={12}>
              <InfiniteCarousel
                breakpoints={[
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                ]}
                dots={false}
                showSides={true}
                sidesOpacity={.5}
                sideSize={.1}
                slidesToScroll={4}
                slidesToShow={4}
                scrollOnDevice={true}
                swipe={true}
                autoCycle={true}
                animationDuration={400}
              >
                <div>
                  <img
                    alt=''
                    src={amity}
                  />
                </div>
                <div>
                  <img
                    alt=''
                    src={gems}
                  />
                </div>
                <div>
                  <img
                    alt=''
                    src={swiss}
                  />
                </div>
                <div>
                  <img
                    alt=''
                    src={jebel}
                  />
                </div>
                <div>
                  <img
                    alt=''
                    src={sohau}
                  />
                </div>
                <div>
                  <img
                    alt=''
                    src={koparu}
                  />
                </div>


              </InfiniteCarousel>
            </Col>

          </Row>
        </Container>
      </section>

      <section id="webinar-slide" className="section section-md logo-slider-secbg pt-lg-3">
        <Container>
          <Row>
            <Col className="heading1" lg={{ span: 10, offset: 1 }}>
              <h1 className="fw-bolder clr_sec text-center">Join 100 of organizers hosting creative events on <span className="clr_primary">webinar.media</span></h1>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center">
            <Col lg={12}>
              <Webinarcards />
            </Col>

          </Row>
        </Container>
      </section>

      <section className="section section-md pt-lg-0 pb-0" >
        <Container className="heading1">
          <Row className="mb-5">
            <Col  lg={8}>
              <Row >
                <h2 className="title-h1 clr_sec">Moving your <span className="clr_primary">event online</span> actually</h2>
                <h2 className="title-h1 clr_sec mb-4">amplifies the value of sponsoring it</h2>
                <p className="mb-4">Get our free ebook How to Generate 6-Figure Value for Virtual Event Sponsors today.</p>
                <div>
                  <Form className="mt-4 d-flex form1 col-12">
                    <Form.Group>
                      <InputGroup className="input-group-merge newslett_txt">
                        <Form.Control type="text" placeholder="Your Mail Here" />
                      </InputGroup>
                    </Form.Group>


                    <Button variant="primary" type="submit" className="new-btn">
                      Sign up
                  </Button>
                  </Form>
                </div>
              </Row>
            </Col>
            <Col lg={4} className="z-cent-btn btn-lp col-12">
              <Row className="text-center verify-user-wrapper">
                <div className="iconImg mb-2"><Image src={VerifyImg} /></div>
                <div className="title-h1 clr_sec fw800">500 +</div>
                <div className="clr_sec">verified active users</div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

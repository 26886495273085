import React, { Component } from 'react'  
import "slick-carousel/slick/slick.css";  
import "slick-carousel/slick/slick-theme.css";  
import Slider from "react-slick";  
import SliderImg1 from "../assets/img/icons/img1.svg";
import SliderImg2 from "../assets/img/icons/img2.svg";
import SliderImg3 from "../assets/img/icons/img1.svg";
export class SlickDemo extends Component {  
    render() {  
        var settings = {  
          dots: true,  
          infinite: true,  
          speed: 500,  
          centerMode: true,  
          slidesToShow: 1,  
          slidesToScroll: 1  
          };  
          return (  
            <div>  
           
            <Slider {...settings} >  
              <div className="wdt">  
              <img  className="img" src= {SliderImg1} className="img"/>  
              </div>  
              <div className="wdt">  
              <img  className="img"  src= {SliderImg2} className="img"/>  
              </div>  
              <div className="wdt">  
              <img  className="img" src= {SliderImg3} className="img"/>  
              </div>  
             
            </Slider>  
            </div>  
          );  
        }  
      }  
  
export default SlickDemo 
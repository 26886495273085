
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";
import TotalRevenueImg from "../../assets/img/icons/total-revenue.svg";
import CancelledEventImg from "../../assets/img/icons/canceled-event.svg";
import EventHostedImg from "../../assets/img/icons/event-hosted.svg";
import RegisteredUserImg from "../../assets/img/icons/registered-user.svg";

export default () => {
  return (
    <>

<Row className="justify-content-md-center">

        <Col xs={12} sm={6} xl={3} className="mb-4">
          <CounterWidget
            category="Total number"
            title="340"            
            percentage={50.2}            
            iconColor="shape-secondary"
            icon={RegisteredUserImg}
            graphtitle="Registered Users"
          />
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-4">
          <CounterWidget
            category="Total number"
            title="500"            
            percentage={10.2}
            icon={EventHostedImg}
            iconColor="shape-tertiary"
            graphtitle="Events Hosted"
          />
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-4">
          <CounterWidget
            category="Total number"
            title="750"            
            percentage={12.4}
            icon={CancelledEventImg}
            iconColor="shape-tertiary"
            graphtitle="Cancelled Events"
          />
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-4">
          <CounterWidget
            category="USD"
            title="12500"            
            percentage={6.8}
            icon={TotalRevenueImg}
            iconColor="shape-tertiary"
            graphtitle="Total Revenue"
          />
        </Col>        

      </Row>

      <Row>
      <Col xs={6} className="mb-4">
      <SalesValueWidgetPhone
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col>
        <Col xs={6} className="mb-4">
          <SalesValueWidgetPhone
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col>
      </Row>
          

    </>
  );
};


import moment from "moment-timezone";

export default [
    {
        "invoiceNumber": "021545a4541",
        "meetingId": "021545a4541",
        "status": "Paid",
        "subscription": "Riversand Technologies",
        "OrganizerName": "AbbasStern",
        "NumberofParticipants": "600",
        "Duration": "07.00.00",
        "TotalMinutes": "07.00.00",
        "price": "799,00",
        "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(1, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": "021545a4541",
        "meetingId": "021545a4541",
        "status": "Paid",
        "subscription": "Riversand Technologies",
        "OrganizerName": "AbbasStern",
        "NumberofParticipants": "600",
        "Duration": "07.00.00",
        "TotalMinutes": "07.00.00",
        "price": "799,00",
        "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(1, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": "021545a4541",
        "meetingId": "021545a4541",
        "status": "Paid",
        "subscription": "Riversand Technologies",
        "OrganizerName": "AbbasStern",
        "NumberofParticipants": "600",
        "Duration": "07.00.00",
        "TotalMinutes": "07.00.00",
        "price": "799,00",
        "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(1, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": "021545a4541",
        "meetingId": "021545a4541",
        "status": "Paid",
        "subscription": "Riversand Technologies",
        "OrganizerName": "AbbasStern",
        "NumberofParticipants": "600",
        "Duration": "07.00.00",
        "TotalMinutes": "07.00.00",
        "price": "799,00",
        "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(1, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": "021545a4541",
        "meetingId": "021545a4541",
        "status": "Paid",
        "subscription": "Riversand Technologies",
        "OrganizerName": "AbbasStern",
        "NumberofParticipants": "600",
        "Duration": "07.00.00",
        "TotalMinutes": "07.00.00",
        "price": "799,00",
        "issueDate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "dueDate": moment().subtract(1, "days").add(1, "month").format("DD MMM YYYY")
    }            
]
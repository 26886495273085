import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faExternalLinkAlt, faTimesCircle, faCheckCircle, faCalendarAlt, faUser, faCodeBranch, faShoppingCart, faFolder, faMapMarkedAlt, faPager, faFileCode, faDownload, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faBootstrap, faGithub, faJs, faReact, faSass, faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, Container, ListGroup, InputGroup, Tooltip, OverlayTrigger, Form, Navbar, Nav, Badge } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import { Routes } from "../routes";
// import ThemesbergLogoIcon from "../assets/img/themesberg.svg";
// import ThemesbergLogo from "../assets/img/themesberg-logo.svg";
import { ClickOutside } from 'reactjs-click-outside';

import webinarlogo from "../assets/img/brand/webinar.png";
import fstyle1 from "../assets/img/footer1.svg";
import fstyle2 from "../assets/img/footer2.svg";


export default (props) => {
  const [open, setOpen] = useState(false)

  return (
    <ClickOutside outsideCall={() => setOpen(false)}>
      <div className="bg-white  sticky-top">
        <Navbar collapseOnSelect expanded={open} expand="lg" className="container no-box-shadow">
          <Navbar.Brand href="./" className="me-lg-3 d-flex align-items-center">
            <Image src={webinarlogo} />
            {/* <span className="ms-2 brand-text d-none d-md-inline">Volt React</span> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setOpen(true)} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
            <Nav.Link  href="https://rameezraja.com/webinar-ver-1/">Home</Nav.Link>
              <Nav.Link  href="https://rameezraja.com/webinar-ver-1/about.html">Why Us</Nav.Link>
           
              {/* <Nav.Link as={HashLink} to="" className="d-sm-none d-xl-inline">Customer Stories</Nav.Link> */}
              <Nav.Link href="https://rameezraja.com/webinar-ver-1/pricing.html">Pricing</Nav.Link>
              <Nav.Link as={HashLink} to={Routes.Signin.path} className="d-login sign-btn"><FontAwesomeIcon icon={faUser} className=" d-sm-inline ms-1 " />Login</Nav.Link>
              <Nav.Link as={HashLink} to={Routes.Signup.path} className="d-signup signup-btn"><FontAwesomeIcon icon={faUserPlus} className=" d-sm-inline ms-1 " /> Sign up</Nav.Link>
            </Nav>
            {/* <Nav>
              <Button as={HashLink} to={Routes.Signup.path} variant="outline-white" ><FontAwesomeIcon icon={faUser} className="d-none d-sm-inline ms-1 " /> Sign up</Button>
            </Nav> */}
          </Navbar.Collapse>
        </Navbar>
      </div>

    </ClickOutside>
  );
};

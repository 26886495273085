import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Image, Button, Table, Form, Dropdown, ProgressBar, Pagination, ButtonGroup } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Webinardashboard } from "../components/Tables";
import { PreviousWebinardashboard } from "../components/Tables";


export default () => {
  return (
    <>
    <Row>
    <Col xs={12} sm={12} lg={12}>
    <Tabs>
    <TabList>
      <Tab>Upcoming Webinars</Tab>
      <Tab>Previous Webinars</Tab>
      <Tab>Payment History</Tab>
      <Button variant="info" className="ms-3 btn-sch-web sched-webinar-btn float-right d-none d-lg-block">Schedule a Webinar</Button>
     

    </TabList>

    <TabPanel>
    <Webinardashboard />
    </TabPanel>
    <TabPanel>
    <PreviousWebinardashboard />
    </TabPanel>
    <TabPanel>
      <h2>Payment History</h2>
    </TabPanel>
  </Tabs>
      </Col> 
      <Col xs={12} sm={12} lg={2}>
      <Button variant="info" className="ms-3 btn-sch-web sched-webinar-btn d-lg-none d-xs-block">Schedule a Webinar</Button>
      </Col>   
      </Row>
     
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Presentation from "./Presentation";
import Terms from "./Terms";
import WhyUs from "./why-us";
import AboutUs from "./about-us";
import Pricing from "./pricing";
import Signup from "./signup";
import DashboardOverview from "./dashboard/DashboardOverview";
import Manageevent from "./Transactions";
import RegisteredClients from "./registeredClients";
import Dashboard from "./dashboard";
import Revenue from "./Revenue";
import Createpackage from "./Createpackage";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./sign-in";
import Managewebinar from "./Managewebinar";


// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MainFooter from "../components/Mainfooter";
import MainHeader from "../components/Mainheader";
import Preloader from "../components/Preloader";


import whyUs from './why-us';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /><MainHeader/> <Component {...props} /> <MainFooter/> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Navbar />
        <Sidebar />
        <main className="content">
          <Component {...props} />
        </main>
        <Footer toggleSettings={toggleSettings} />
      </>
    )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />    
    {/* <RouteWithLoader exact path={Routes.WhyUs.path} component={WhyUs} /> */}
    {/* <RouteWithLoader exact path={Routes.Terms.path} component={Terms} /> */}
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    {/* <RouteWithLoader exact path={Routes.AboutUs.path} component={AboutUs} /> */}
    {/* <RouteWithLoader exact path={Routes.Pricing.path} component={Pricing} /> */}
 
    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Manageevent.path} component={Manageevent} />
    <RouteWithSidebar exact path={Routes.RegisteredClients.path} component={RegisteredClients} />
    <RouteWithSidebar exact path={Routes.Revenue.path} component={Revenue} />
    <RouteWithSidebar exact path={Routes.Dashboard.path} component={Dashboard} />
    <RouteWithSidebar exact path={Routes.Managewebinar.path} component={Managewebinar} />
    <RouteWithSidebar exact path={Routes.Createpackage.path} component={Createpackage} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />
    
  
    <Redirect to={Routes.NotFound.path} />
  </Switch>
);

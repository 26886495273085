
import moment from "moment-timezone";

export default [
    {
        "accountid": "021545a4541",
        "companyname": "Test",
        "fullname": "Test User",
        "mobilenumber": "9876543210",
        "email": "test@test.com",        
    },
    {
        "accountid": "021545a4542",
        "companyname": "Test",
        "fullname": "Test User",
        "mobilenumber": "9876543210",
        "email": "test@test.com",        
    },
    {
        "accountid": "021545a4543",
        "companyname": "Test",
        "fullname": "Test User",
        "mobilenumber": "9876543210",
        "email": "test@test.com",        
    },
    {
        "accountid": "021545a4544",
        "companyname": "Test",
        "fullname": "Test User",
        "mobilenumber": "9876543210",
        "email": "test@test.com",        
    },
    {
        "accountid": "021545a4545",
        "companyname": "Test",
        "fullname": "Test User",
        "mobilenumber": "9876543210",
        "email": "test@test.com",        
    },
    {
        "accountid": "021545a4546",
        "companyname": "Test",
        "fullname": "Test User",
        "mobilenumber": "9876543210",
        "email": "test@test.com",        
    }
]
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faExternalLinkAlt, faTimesCircle, faCheckCircle, faCalendarAlt ,faUser, faCodeBranch, faShoppingCart, faFolder, faMapMarkedAlt, faPager, faFileCode, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faBootstrap, faGithub, faJs, faReact, faSass, faFacebookF, faInstagram, faLinkedinIn, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, Container, ListGroup, InputGroup, Tooltip, OverlayTrigger, Form, Navbar, Nav, Badge } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Routes } from "../routes";

import WebinarLImg from "../assets/img/icons/signup.svg";




export default () => {
  
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link as={Link} to={link} className="page-preview page-preview-lg scale-up-hover-2">
          <Image src={image} className="shadow-lg rounded scale" alt="Dashboard page preview" />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name} <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li data-toggle="tooltip" data-placement="left" title="Main folder that you will be working with">
          <FontAwesomeIcon icon={icon ? icon : faFolder} className={`${color} me-2`} /> {name}
        </li>
      </OverlayTrigger>
    );
  };

  return (
    <>

     
      <section className="section section-md pt-80">
        <Container>
          <Row className="mb-5">
            <Col lg={5}>
              <Row className="mb-4">
                  <h1 className="title-h1 clr_sec">Create your webinar.media Account</h1>                                  
              </Row>

              <Row>
                  <Col lg={4}  className="clr_sec fnt-wt-700 nb-btm"><a>Log In</a></Col>
                  <Col lg={4}  className="clr_primary fnt-wt-700"><a>Sign Up</a></Col>
              </Row>

              <Row className="s-form">

                <Form className="mt-4">
                  <Form.Group>
                  <Form.Label>First Name</Form.Label>
                    <InputGroup className="input-group-merge ">                  
                      <Form.Control type="text" placeholder="First Name goes here" />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                    <InputGroup className="input-group-merge ">                  
                      <Form.Control type="text" placeholder="Last Name goes here" />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                  <Form.Label>E-Mail</Form.Label>
                    <InputGroup className="input-group-merge ">                  
                      <Form.Control type="email" placeholder="Your e-mail address goes here" />
                    </InputGroup>
                  </Form.Group>                  


                  <Form.Group>
                  <Form.Label>Password</Form.Label>
                    <InputGroup className="input-group-merge ">                  
                      <Form.Control type="password" placeholder="**********" />
                    </InputGroup>
                  </Form.Group>

                  <div class="form-group f-radio-btn d-flex mt-4">
                   <input class="form-check-input" type="checkbox" value=""></input>
                   <label class="form-check-label">By creating this account, you agree to webinar.media Terms & Conditions and the Privacy Policy. (Required)</label>
                 </div>                               

                 <div class="form-group f-radio-btn d-flex mt-4">
                   <input class="form-check-input" type="checkbox" value=""></input>
                   <label class="form-check-label">I agree to receive product updates and marketing communications from webinar.media</label>
                 </div> 

                  <Button variant="primary" type="submit" className="btn-reset">
                    Sign up
                  </Button>
                </Form>
              </Row>

              <Row className="mt-4">
                <div className="mb-30">Or continue with</div>
                <div className="d-flex w-100 justify-content-between flex-wrap">
                <Col  className="mb-20 "> <a className="fb-clr "><FontAwesomeIcon icon={faFacebookF} /> Facebook</a></Col>
                <Col  className="mb-20 ">  <a className="lnkIn-clr "><FontAwesomeIcon icon={faLinkedinIn} /> LinkedIn</a></Col>
                <Col > <a className="google-clr mb-30"><FontAwesomeIcon icon={faGoogle} /> Google</a></Col>
                </div>
               
                
              </Row>

              
              
            </Col>
            <Col lg={7} className="z-cent-btn btn-lp d-none d-lg-block">
            <Image src={WebinarLImg} />
            </Col>
          </Row>
        </Container>
      </section>

    </>
  );
};

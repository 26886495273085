import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, ToggleButton, Card, Image, Button, Table, Form, Dropdown, ProgressBar, Pagination, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HashLink } from 'react-router-hash-link';
import { Routes } from "../routes";
import Googlecalendar from "../assets/img/icons/google-calendar.svg";
import Outlook from "../assets/img/icons/outlook.svg";
import Yahoo from "../assets/img/icons/yahoo.svg";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default () => {
    const [checked, setChecked] = React.useState(false);
    function myFunction() {
        var x = document.getElementById("webinar_pass");
        var y = document.getElementsByClassName("passbtn");

        if (x.type === "password") {
          x.type = "text";
          y.innerHTML = "Hide";

        } else {
          x.type = "password";
          y.innerHTML = "Show";
        }
      }
    return (
        <>
            <Row className="line-height-2">
                <Col xs={12} sm={10} lg={10}>
                    <div><Card.Link as={Link} to={Routes.Dashboard.path}>My Webinars </Card.Link> > The name of the webinar </div>
                </Col>
                <Col xs={12} sm={2} lg={2}>
                    <Button variant="info" className="ms-3 btn-sch-web sched-webinar-btn">Start this Webinar</Button>
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Topic</div>
                    <div>Description</div>
                </Col>
                <Col xs={10}>
                    <div>TestSameer</div>
                    <div>TestReddy</div>
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Time</div>
                </Col>
                <Col xs={10}>
                    <div>Sep 19, 2021 11:59 PM Pacific Time (US and Canada)</div>
                    <div className="d-flex">Addto <div><Button className="mngwebinar_timebtns googlecalendar" variant="outline-primary"><Image src={Googlecalendar} />Google Calendar</Button>
                        <Button className="mngwebinar_timebtns outlook" variant="outline-primary"><Image src={Outlook} />Outlook Calendar (.ics)
                  </Button>
                        <Button className="mngwebinar_timebtns yahoocalandar" variant="outline-primary"><Image src={Yahoo} />Yahoo Calendar</Button></div></div>
                    <p><ButtonGroup toggle className="mb-2 checkbox">
                        <ToggleButton
                            type="checkbox"
                            checked={checked}
                            value="1"
                            onChange={e => setChecked(e.currentTarget.checked)}
                        >
                        </ToggleButton>
                    </ButtonGroup>List this webinar in the Public Event List</p>
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Webinar ID</div>
                </Col>
                <Col xs={10}>
                    <div>843 7274 9509</div>
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Webinar Passcode</div>
                </Col>
                <Col xs={10}>
                    <div className="webinar_pass"><input id="webinar_pass" type="password" name="password" value="test" readOnly/><span className="passbtn" onclick="myFunction()">Show</span></div>
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Video</div>
                   
                </Col>
                <Col xs={10}>
                    <div>Host<span>On</span></div>
                    <div>Panelists<span>On</span></div>
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Audio</div>
                   
                </Col>
                <Col xs={10}>
                    <div>Telephone and Computer Audio</div>
                    <div>Dial from United States</div>
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Webinar Options</div>
                   
                </Col>
                <Col xs={10}>
                    <div><ul>
                        <li>Q&A</li>
                        <li>Enable Practice Session</li>
                        <li>Require authentication to join</li>
                        <li>Make the webinar on-demand</li>
                        <li>Automatically record webinar</li>
                        <li>Approve or block entry for users from specific countries/regions</li>
                        </ul></div>
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={6}>
                <Button variant="info" className="ms-3 btn-sch-web sched-webinar-btn">Start</Button>
                <Button variant="info" className="ms-3 btn-sch-web sched-webinar-btn">Edit</Button>
                <Button variant="info" className="ms-3 btn-sch-web sched-webinar-btn">Save as Template</Button>
                   
                </Col>
                <Col xs={6}>
                    <div>Want a meeting instead of a webinar?<a>Convert this Webinar to a Meeting</a></div></Col>
            </Row>
            <Row className="line-height-2 border-b-1">
    <Col xs={12} sm={12} lg={12}>
    <Tabs>
    <TabList>
      <Tab>Invitations</Tab>
      <Tab>Email Settings</Tab>
      <Tab>Branding</Tab>
      <Tab>Polls/Survey</Tab>
      <Tab>Q&A</Tab>
      <Tab>More</Tab>

    </TabList>

    <TabPanel>
    <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Invite Panelists</div>
                   
                </Col>
                <Col xs={7}>
                    <div>Mary (maryjkdfdsgfshdgf@jdfdkjdglfk.jkfgdj)</div>
                </Col>
                <Col xs={3}>
                    <div><span>Import from CSV </span><span>Edit</span></div>
                   
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Invite Attendees</div>
                   
                </Col>
                <Col xs={6}>
                    <div>Mary (maryjkdfdsgfshdgf@jdfdkjdglfk.jkfgdj)</div>
                    <div><b>Registration Link</b>
https://theincube-it.zoom.us/webinar/register/WN_wxur8I_eRIuVmKwd5XAW</div>
<div>Source Tracking Link </div>
<div>You have not yet created any source tracking links</div>
                </Col>
                <Col xs={4}>
                    <div><span> Copy Invitation</span><span> Send Invitation to Me</span></div>
                   
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Registration Settings</div>
                   
                </Col>
                <Col xs={8}>
                    <div>
                        <div>Automatically Approve</div>
                        <ul>
                        <li>Close registration after event date</li>
                        <li>Allow attendees to join from multiple devices</li>
                        <li>Show social share buttons on registration page</li>
                        <li>Enable Disclaimer</li>
                        </ul></div>
                </Col>
                <Col xs={2}>
                    <div>Edit</div>
                   
                </Col>
            </Row>
            <Row className="line-height-2 border-b-1">
                <Col xs={2}>
                    <div>Manage Attendees
</div>
                   
                </Col>
                <Col xs={6}>
                    <div><b>Total Registrants: 1</b></div>
                    <div>Approved: 1      Denied/Blocked: 0</div>
                </Col>
                <Col xs={4}>
                    <div><span> Import from CSV | Edit</span></div>
                   
                </Col>
            </Row>
    </TabPanel>
    <TabPanel>

    </TabPanel>
    <TabPanel>
     
    </TabPanel>
    <TabPanel>
     
    </TabPanel>
    <TabPanel>
     
    </TabPanel>
    <TabPanel>
     
    </TabPanel>
  </Tabs>
      </Col> 
       </Row>
     
        </>
    );
};

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, Image, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RegisteredUsersTable } from "../components/Tables";
import CalanderImg from "../assets/img/icons/calander1.svg";

const Datepick = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
  );
};

export default () => {
  return (
    <>
    

      <div className="table-settings mt-4 mb-4 col-xl-6">
        <Row className="justify-content-between align-items-center">
          <Col xs={6} md={4} lg={4} xl={4} className="txt_rg"><Image src={CalanderImg} className="dash-cal-img" />  <Datepick /></Col>
          <Col xs={6} md={4} lg={4} xl={4}>
          <Form.Group>
          <Form>
              <Form.Group id="cust_name">
                <Form.Control required type="text" placeholder="Enter Customer Name" />
              </Form.Group>
            </Form>
 
</Form.Group>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={12} md={4} lg={4} xl={4} className="btn_s_wrapper">
          <Button variant="info" size="sm" className="btn_search">Search</Button>
          </Col>
          <Col xs={4} md={2} lg={3} xl={3} className="ps-md-0 text-end">
            {/* <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </Col>
        </Row>
      </div>

      <RegisteredUsersTable />
    </>
  );
};

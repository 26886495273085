import moment from "moment-timezone";

export default [
    {
        "serialno": "021545a4541",
        "licensetype": "Paid",
        "noofparticipants": "200",
        "amount": "799,00",
        "purchasedate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "company": "Test Company"
    },
    {
        "serialno": "021545a4542",
        "licensetype": "Paid",
        "noofparticipants": "350",
        "amount": "799,00",
        "purchasedate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "company": "Test Company"
    },
    {
        "serialno": "021545a4543",
        "licensetype": "Paid",
        "noofparticipants": "620",
        "amount": "799,00",
        "purchasedate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "company": "Test Company"
    },
    {
        "serialno": "021545a4544",
        "licensetype": "Paid",
        "noofparticipants": "1000",
        "amount": "799,00",
        "purchasedate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "company": "Test Company"
    },
    {
        "serialno": "021545a4545",
        "licensetype": "Paid",
        "noofparticipants": "9000",
        "amount": "799,00",
        "purchasedate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "company": "Test Company"
    },
    {
        "serialno": "021545a4546",
        "licensetype": "Paid",
        "noofparticipants": "4000",
        "amount": "799,00",
        "purchasedate": moment().subtract(1, "days").format("DD MMM YYYY"),
        "company": "Test Company"
    },
]